import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { firstValueFrom } from 'rxjs';

export const unauthenticatedGuard: CanActivateFn = async (_route, _state) => {
  const auth = inject(AuthService);
  const router = inject(Router);
  const isAuthenticated = await firstValueFrom(auth.isAuthenticated$);

  if (isAuthenticated) {
    await router.navigate(['/dashboard']);
    return false;
  }

  return true;
};
