<h1 mat-dialog-title>{{ survey.name }}</h1>
<div mat-dialog-content>
  <section>
    <h2>Description</h2>
    <p>{{ survey.description }}</p>
  </section>

  <section>
    <h2>Questions</h2>
    <mat-list>
      @for(question of survey.questions; track question.id) {
        <p matListItemTitle>{{question.prompt}}</p>
        <p matListItemLine>{{question.type}}</p>
      }
    </mat-list>
  </section>
</div>

<div mat-dialog-actions>
  <button aria-label="Close the dialog" color="warn" mat-dialog-close mat-flat-button matTooltip="Close">
    <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
    Close
  </button>
</div>
