import { Component, computed, input, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'app-select',
    imports: [
        FormsModule,
        MatFormFieldModule,
        MatSelectModule
    ],
    templateUrl: './select.component.html',
    styleUrl: './select.component.scss'
})
export class SelectComponent<T> {
  value = model<T>();
  options = model<{value: T, name: string}[] | string[]>([]);
  valid = model<boolean>(false);
  placeholder = model<string>("");
  label = model<string>("");
  disabled = input<boolean>(false);
  required = input<boolean>(false);
  optionValues = computed(() => {
    return this.options().map(option => {
      if (typeof option === 'string') {
        return {value: option, name: option};
      } else {
        return option;
      }
    });
  });
}
