import {
  ApplicationConfig,
  ErrorHandler,
  inject,
  provideAppInitializer,
  provideZoneChangeDetection
} from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthClientConfig, authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { ServerConfigService } from '@services/server-config.service';
import * as Sentry from '@sentry/angular';
import { sentryErrorHandlerInterceptor } from '@app/interceptors/sentry-error-handler.interceptor';
import { loggerInterceptor } from '@app/interceptors/logger.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAuth0(),
    provideAnimations(),
    provideHttpClient(withInterceptors([
      loggerInterceptor,
      sentryErrorHandlerInterceptor,
      authHttpInterceptorFn
    ])),
    provideAppInitializer(async () => {
      const serverConfigService = inject(ServerConfigService);
      const authClientConfig = inject(AuthClientConfig)
      const authConfig = await serverConfigService.getAuthConfig();
      authClientConfig.set(authConfig);
    }),
    {
      provide: Window,
      useValue: window
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
  ]
};
