<span matDialogTitle>Create Caregiver</span>

<mat-dialog-content>
  <div class="field-row">
    <app-input type="email" [required]="true" [(email)]="email" [(valid)]="emailValid" />
  </div>
  <div class="field-row">
    <app-first-name-input [required]="true" [(firstName)]="firstName" [(valid)]="firstNameValid" />
    <app-last-name-input [required]="true" [(lastName)]="lastName" [(valid)]="lastNameValid" />
  </div>
  <div class="field-row">
    <app-caregiver-role-select [required]="true" [(role)]="role" [(valid)]="roleValid" />
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button color="warn" (click)="onNoClick()" matDialogClose>
    Cancel <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
  </button>

  <button mat-raised-button color="primary" [disabled]="!formValid()" (click)="submit()">
    Create <mat-icon fontSet="material-symbols-outlined">upload</mat-icon>
  </button>
</mat-dialog-actions>
