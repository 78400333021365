<app-input
  type="number"
  [value]="weight()"
  (valueChange)="weight.set($event)"
  [valid]="valid()"
  (validChange)="valid.set($event)"
  [min]="0"
  [max]="1000"
  [disabled]="disabled()"
  [required]="required()"
  placeholder="Weight"
  label="Weight"
  [suffix]="units()"
/>
