import { Component, inject } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { OrganizationService } from '@services/API/organization.service';
import { OrganizationCreation } from '@models';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import * as Sentry from '@sentry/angular';

@Component({
    selector: 'app-create-organization-dialog',
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatListModule
    ],
    templateUrl: './create-organization-dialog.component.html',
    styleUrl: './create-organization-dialog.component.scss'
})
export class CreateOrganizationDialogComponent {
  private dialogRef = inject<MatDialogRef<CreateOrganizationDialogComponent>>(MatDialogRef);
  private formBuilder = inject(FormBuilder);
  private organizationService = inject(OrganizationService);

  formGroup: FormGroup = this.formBuilder.group({
    name: [
      '',
      Validators.compose([Validators.required, Validators.maxLength(64)]),
    ],
    email: [
      '',
      Validators.compose([
        Validators.required,
        Validators.email,
        Validators.maxLength(256),
      ]),
    ],
    phone: [
      '',
      Validators.compose([Validators.required, Validators.maxLength(32)]),
    ],
    address: [
      '',
      Validators.compose([Validators.required, Validators.maxLength(256)]),
    ],
    profile_picture: [null],
  });

  get profilePicture(): File | null {
    return this.formGroup.get('profile_picture')!.value;
  }

  set profilePicture(file: File | null) {
    this.formGroup.get('profile_picture')!.setValue(file);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFileSelected(event: any) {
    if (event.target.files.length === 0) {
      return;
    }
    this.profilePicture = event.target.files[0];
  }

  clearFileSelection() {
    this.profilePicture = null;
  }

  async submit() {
    const {name, email, phone, address} = this.formGroup.value;
    const organization: OrganizationCreation = {
      name,
      email,
      phone,
      address,
    };
    try {
      await this.organizationService.createOrganization(organization, this.profilePicture);
      this.dialogRef.close({success: true, error: null});
    } catch (error) {
      Sentry.captureException(error);
      this.dialogRef.close({success: false, error: error});
    }
  }
}
