import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { SurveyInfoDialogComponent } from '@components/dialogs/survey-info-dialog/survey-info-dialog.component';
import { ExerciseInfoDialogComponent } from '@components/dialogs/exercise-info-dialog/exercise-info-dialog.component';
import {
  FunctionalTestInfoDialogComponent
} from '@components/dialogs/functional-test-info-dialog/functional-test-info-dialog.component';
import {
  CreateOrganizationDialogComponent
} from '@components/dialogs/create-organization-dialog/create-organization-dialog.component';
import { OrganizationID, SurveyResponse } from "@models";
import {
  CreateCaregiverDialogComponent
} from '@components/dialogs/create-caregiver-dialog/create-caregiver-dialog.component';
import {
  CreatePatientDialogComponent
} from '@components/dialogs/create-patient-dialog/create-patient-dialog.component';
import {
  SurveyResponseDialogComponent
} from '@components/dialogs/survey-response-dialog/survey-response-dialog.component';

export interface DialogCloseResult {
  success: boolean;
  error: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private readonly dialog = inject(MatDialog);

  closeAllDialogs() {
    this.dialog.closeAll();
  }

  async openDialog(component: any, data?: any): Promise<any> {
    const dialogRef = this.dialog.open(component, {
      data,
    });

    return await firstValueFrom(dialogRef.afterClosed());
  }

  async openSurveyResponseDialog(surveyName: string, response: SurveyResponse) {
    const dialogRef = this.dialog.open(SurveyResponseDialogComponent, {
      data: {surveyName, response},
      disableClose: true,
    });

    return await firstValueFrom(dialogRef.afterClosed());
  }

  async openCreateOrganizationDialog() {
    const dialogRef = this.dialog.open(CreateOrganizationDialogComponent, {
      disableClose: true,
    });

    return await firstValueFrom(dialogRef.afterClosed());
  }

  async openCreateUserDialog(organizationID: OrganizationID): Promise<{
    success: boolean,
    error: unknown
  } | undefined> {
    const dialogRef = this.dialog.open(CreateCaregiverDialogComponent, {
      data: {organizationID},
      disableClose: true,
    });

    return await firstValueFrom(dialogRef.afterClosed());
  }

  async openCreatePatientDialog(organizationID: OrganizationID): Promise<{
    success: boolean,
    error: unknown
  } | undefined> {
    const dialogRef = this.dialog.open(CreatePatientDialogComponent, {
      data: {organizationID},
      disableClose: true,
    });

    return await firstValueFrom(dialogRef.afterClosed());
  }

  openSurveyInfoDialog(survey: any) {
    this.dialog.open(SurveyInfoDialogComponent, {
      data: survey,
      width: '90vw',
      height: '90vh',
      maxWidth: '500px',
      maxHeight: '700px',
      autoFocus: false,
    });
  }

  openExerciseInfoDialog(exercise: any) {
    return this.dialog.open(ExerciseInfoDialogComponent, {
      data: exercise,
      width: '90vw',
      height: '90vh',
      maxWidth: '500px',
      maxHeight: '700px',
      autoFocus: false,
    });
  }

  openFunctionalTestInfoDialog(functionalTest: any) {
    this.dialog.open(FunctionalTestInfoDialogComponent, {
      data: functionalTest,
      width: '90vw',
      height: '90vh',
      maxWidth: '500px',
      maxHeight: '700px',
      autoFocus: false,
    });
  }
}
