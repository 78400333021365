import { inject, Injectable } from '@angular/core';
import { Caregiver, CaregiverCreate, CaregiverFilters, CaregiverUpdate, OrganizationID, UserID } from '@models';
import { ApiService } from '@services/API/api.service';

@Injectable({
  providedIn: "root"
})
export class CaregiverService {
  private readonly api = inject(ApiService);

  public getCaregivers(
    organizationID: OrganizationID,
    page: number,
    limit: number,
    filters?: CaregiverFilters): Promise<{ data: Caregiver[], total: number }> {
    return this.api.get<{ data: Caregiver[], total: number }>(`organizations/${organizationID}/caregivers`, {
      "page": page.toString(),
      "limit": limit.toString(),
      "search": filters?.search,
      "role": filters?.role
    });
  }

  public getCaregiver(organizationID: OrganizationID, userID: UserID): Promise<Caregiver> {
    return this.api.get<Caregiver>(`organizations/${organizationID}/caregivers/${userID}`);
  }

  public createCaregiver(organizationID: OrganizationID, user: CaregiverCreate): Promise<string> {
    return this.api.post<string>(`organizations/${organizationID}/caregivers`, user, {responseType: "text"});
  }

  public updateCaregiver(organizationID: OrganizationID, userID: UserID, user: CaregiverUpdate): Promise<string> {
    return this.api.put<string>(`organizations/${organizationID}/caregivers/${userID}`, user, {responseType: "text"});
  }

  public deleteCaregiver(organizationID: OrganizationID, userID: UserID): Promise<string> {
    return this.api.delete<string>(`organizations/${organizationID}/caregivers/${userID}`, {responseType: "text"});
  }

  public assignPatientToCaregiver(organizationID: OrganizationID, userID: UserID, patientID: UserID): Promise<string> {
    return this.api.post<string>(`organizations/${organizationID}/caregivers/${userID}/patients/${patientID}`, {}, {responseType: "text"});
  }

  public unassignPatientFromCaregiver(organizationID: OrganizationID, userID: UserID, patientID: UserID): Promise<string> {
    return this.api.delete<string>(`organizations/${organizationID}/caregivers/${userID}/patients/${patientID}`, {responseType: "text"});
  }

  public getPatientCaregivers(organizationID: OrganizationID, patientID: UserID): Promise<{
    data: Caregiver[],
    total: number
  }> {
    return this.api.get<{ data: Caregiver[], total: number }>(`organizations/${organizationID}/patients/${patientID}/caregivers`);
  }
}
