import { Injectable } from '@angular/core';
import { environment } from '@environment';
import pino from 'pino';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private logger = pino({
    level: 'info',
    browser: {
      asObject: true,
    },
  });

  constructor() {
    this.logger.level = environment.logLevel;
  }

  error(message: string, ...args: any[]): void {
    this.logger.error(message, ...args);
  }

  warn(message: string, ...args: any[]): void {
    this.logger.warn(message, ...args);
  }

  info(message: string, ...args: any[]): void {
    this.logger.info(message, ...args);
  }

  debug(message: string, ...args: any[]): void {
    this.logger.debug(message, ...args);
  }

  assert(condition: boolean, message: string, ...args: any[]): void {
    if (!condition) {
      this.error(message, ...args);
    }
  }
}
