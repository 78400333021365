<app-input
  type="text"
  [value]="lastName()"
  (valueChange)="lastName.set($event)"
  [valid]="valid()"
  (validChange)="valid.set($event)"
  [minLength]="0"
  [maxLength]="64"
  [disabled]="disabled()"
  [required]="required()"
  placeholder="Last Name"
  label="Last Name"
/>
