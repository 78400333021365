<mat-form-field>
  @if (label()) {
    <mat-label>{{ label() }}</mat-label>
  }
  <input
    #control="ngModel"
    matInput
    type="number"
    inputmode="numeric"
    [pattern]="allow_decimals() ? '-?[0-9]*[.]?[0-9]*' : '-?[0-9]*'"
    [placeholder]="placeholder() ?? ''"
    [(ngModel)]="value"
    (ngModelChange)="valid.set(control.errors === null)"
    [min]="min() ?? null"
    [max]="max() ?? null"
    [required]="required()"
    [disabled]="disabled()" />
  @if (suffix()) {
    <span matTextSuffix>{{ suffix() }}</span>
  }
  @if (control.errors && control.errors['min']) {
    <mat-error>Must be greater than {{ min() }}.</mat-error>
  } @else if (control.errors && control.errors['max']) {
    <mat-error>Must be less than {{ max() }}.</mat-error>
  } @else if (control.errors && control.errors['pattern'] && !allow_decimals() && value() && value()! % 1 !== 0) {
    <mat-error>Must be a whole number.</mat-error>
  } @else if (control.errors && control.errors['pattern']) {
    <mat-error>Must be a number.</mat-error>
  } @else if (control.errors && control.errors['required']) {
    <mat-error>Required.</mat-error>
  }
</mat-form-field>
