import { Component, computed, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '@auth0/auth0-angular';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { StateService } from '@app/services/state.service';
import { UserRoles } from '@models';
import { RouterService } from '@services/router.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-profile-button',
    imports: [
        MatMenuModule,
        MatListModule,
        MatIconModule,
        RouterLink,
        RouterLinkActive,
    ],
    templateUrl: './profile-button.component.html',
    styleUrl: './profile-button.component.scss'
})
export class ProfileButtonComponent {
  private stateService = inject(StateService);
  private router = inject(RouterService);
  private document = inject<Document>(DOCUMENT);
  private authService = inject(AuthService);

  protected profilePicture = toSignal(this.stateService.getUserProfilePicture());
  protected role = toSignal(this.stateService.getRole());
  protected profileRoute = computed(() => {
    if (this.role() === UserRoles.PATIENT) {
      return this.router.routePatientProfile();
    }
    if (this.role() === UserRoles.THERAPIST || this.role() === UserRoles.ADMIN) {
      return this.router.routeCaregiverProfile();
    }
    return this.router.routeDashboard();
  });

  protected settingsRoute = this.router.routeSettings();

  protected logout(): void {
    this.authService.logout({
      logoutParams: {
        returnTo: this.document.location.origin,
      },
    });
  }
}
