import { Component, input, model, OnInit } from '@angular/core';
import { SafePipe } from '@app/pipes/safe.pipe';

@Component({
    selector: 'app-youtube-video',
    imports: [
        SafePipe
    ],
    templateUrl: './youtube-video.component.html',
    styleUrl: './youtube-video.component.scss'
})
export class YoutubeVideoComponent implements OnInit{
  public readonly src = model<string>();
  public readonly autoplay = input<boolean>(false);

  ngOnInit() {
    const src = this.src();
    if (src) {
      // Extract the video ID from the YouTube URL
      const videoId = this.getVideoId(src);
      if (videoId) {
        // Construct the new YouTube URL with conditional autoplay
        this.src.set(`https://www.youtube.com/embed/${videoId}` + (this.autoplay() ? `?autoplay=1` : ''));
      }
    }
  }

  private getVideoId(url: string): string | null {
    const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\?v=|v\/|embed\/|watch\?v=))([^&\n?#]+)/);
    return match ? match[1] : null;
  }
}
