<mat-form-field>
  <mat-label>Date of Birth</mat-label>
  <input
    #control="ngModel"
    matInput
    [matDatepicker]="picker"
    [(ngModel)]="dateOfBirth"
    (ngModelChange)="valid.set(control.errors === null)"
    [disabled]="disabled()"
    [required]="required()">
  <mat-hint>MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
