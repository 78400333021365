import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ServerConfigService } from '@services/server-config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly http = inject(HttpClient);
  private readonly serverConfig = inject(ServerConfigService);

  private createParams(params?: Record<string, string | undefined>): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if(value !== undefined) {
          httpParams = httpParams.set(key, value);
        }
      });
    }
    return httpParams;
  }

  private createHeaders(headers?: Record<string, string>): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    if (headers) {
      Object.entries(headers).forEach(([key, value]) => {
        httpHeaders = httpHeaders.set(key, value);
      });
    }
    return httpHeaders;
  }

  public async get<T>(endpoint: string, params?: Record<string, string | undefined>, headers?: Record<string, string>): Promise<T> {
    const serverUrl = await this.serverConfig.getServerUrl();
    const url = `${serverUrl}/${endpoint}`;
    return await firstValueFrom(this.http.get<T>(url, { params: this.createParams(params), headers: this.createHeaders(headers) }));
  }

  public async post<T>(endpoint: string, body: any, headers?: Record<string, string>): Promise<T> {
    const serverUrl = await this.serverConfig.getServerUrl();
    const url = `${serverUrl}/${endpoint}`;
    return await firstValueFrom(this.http.post<T>(url, body, { headers: this.createHeaders(headers) }));
  }

  public async put<T>(endpoint: string, body: any, headers?: Record<string, string>): Promise<T> {
    const serverUrl = await this.serverConfig.getServerUrl();
    const url = `${serverUrl}/${endpoint}`;
    return await firstValueFrom(this.http.put<T>(url, body, { headers: this.createHeaders(headers) }));
  }

  public async delete<T>(endpoint: string, headers?: Record<string, string>): Promise<T> {
    const serverUrl = await this.serverConfig.getServerUrl();
    const url = `${serverUrl}/${endpoint}`;
    return await firstValueFrom(this.http.delete<T>(url, { headers: this.createHeaders(headers) }));
  }
}
