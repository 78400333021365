<app-select
  label="Role"
  placeholder="Select a Role"
  [value]="role()"
  (valueChange)="role.set($event)"
  [valid]="valid()"
  (validChange)="valid.set($event)"
  [options]="options()"
  [disabled]="disabled()"
  [required]="required()"
></app-select>
