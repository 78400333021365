import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { PatientSex } from '@models';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { PatientService } from '@services/API/patient.service';
import { UserPreferencesService } from '@services/user-preferences.service';
import { UnitConversion } from '@app/util/unit-conversion';
import { InputEmailComponent } from '@components/forms/fields/input-email/input-email.component';
import {
  PatientDateOfBirthInputComponent
} from '@components/forms/fields/patient-date-of-birth-input/patient-date-of-birth-input.component';
import { FirstNameInputComponent } from '@components/forms/fields/first-name-input/first-name-input.component';
import {
  PatientHeightInputComponent
} from '@components/forms/fields/patient-height-input/patient-height-input.component';
import { LastNameInputComponent } from '@components/forms/fields/last-name-input/last-name-input.component';
import {
  PatientWeightInputComponent
} from '@components/forms/fields/patient-weight-input/patient-weight-input.component';
import { MatIcon } from '@angular/material/icon';
import { PatientSexSelectComponent } from '@components/forms/fields/patient-sex-select/patient-sex-select.component';
import * as Sentry from '@sentry/angular';

@Component({
    selector: 'app-create-patient-dialog',
    providers: [
        provideNativeDateAdapter()
    ],
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        InputEmailComponent,
        PatientDateOfBirthInputComponent,
        FirstNameInputComponent,
        PatientHeightInputComponent,
        LastNameInputComponent,
        PatientWeightInputComponent,
        MatIcon,
        PatientSexSelectComponent
    ],
    templateUrl: "./create-patient-dialog.component.html",
    styleUrl: "./create-patient-dialog.component.scss"
})
export class CreatePatientDialogComponent implements OnInit {
  protected readonly data = inject(MAT_DIALOG_DATA);


  email = signal<string | undefined>(undefined);
  emailValid = signal<boolean>(false);
  height = signal<number | undefined>(undefined);
  heightValid = signal<boolean>(false);
  heightUnits = signal<"in" | "cm">("cm");
  weight = signal<number | undefined>(undefined);
  weightValid = signal<boolean>(false);
  weightUnits = signal<"kg" | "lbs">("kg");
  firstName = signal<string | undefined>(undefined);
  firstNameValid = signal<boolean>(false);
  lastName = signal<string | undefined>(undefined);
  lastNameValid = signal<boolean>(false);
  dateOfBirth = signal<Date | undefined>(undefined);
  dateOfBirthValid = signal<boolean>(false);
  sex = signal<PatientSex>("Other");
  sexValid = signal<boolean>(false);

  formValid = computed<boolean>(() => {
    return (
      this.emailValid() &&
      this.firstNameValid() &&
      this.lastNameValid() &&
      this.dateOfBirthValid() &&
      this.weightValid() &&
      this.heightValid() &&
      this.sexValid()
    )
  })

  private preferenceService = inject(UserPreferencesService);
  private patientService = inject(PatientService);
  private dialogRef = inject(MatDialogRef<CreatePatientDialogComponent>);

  async ngOnInit() {
    if (!this.preferenceService.getPreference("isMetric")) {
      this.heightUnits.set("in");
      this.weightUnits.set("lbs");
    }
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  async submit() {
    if (!this.formValid()) {
      return;
    }

    let heightCM = this.height()!;
    let weightKG = this.weight()!;
    if(this.heightUnits() === 'in') {
      heightCM = UnitConversion.inchesToCentimeters(heightCM);
    }
    if(this.weightUnits() === 'lbs') {
      weightKG = UnitConversion.poundsToKilograms(weightKG);
    }

    try {
      await this.patientService.createPatient(this.data.organizationID, {
        first_name: this.firstName()!,
        last_name: this.lastName()!,
        height: Math.round(heightCM),
        weight: Math.round(weightKG),
        date_of_birth: this.dateOfBirth()!,
        profile_picture: null,
        email: this.email()!,
        sex: this.sex()
      });
      this.dialogRef.close({success: true, error: null})
    } catch (error) {
      Sentry.captureException(error);
      this.dialogRef.close({success: false, error})
    }
  }
}
