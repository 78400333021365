import { Component, input, signal } from '@angular/core';

@Component({
    selector: 'app-loading-spinner',
    imports: [],
    templateUrl: './loading-spinner.component.html',
    styleUrl: './loading-spinner.component.scss'
})
export class LoadingSpinnerComponent {
  public readonly debounceTimeMilliseconds = input<number>(1000);

  private _isVisible = signal(false);
  protected get isVisible() {
    return this._isVisible.asReadonly();
  }

  ngOnInit() {
    setTimeout(() => {
      this._isVisible.set(true);
    }, this.debounceTimeMilliseconds());
  }
}
