
export class UnitConversion {
  public static centimetersToInches(centimeters: number): number {
    return centimeters * 0.393701;
  }

  public static inchesToCentimeters(inches: number): number {
    return inches * 2.54;
  }

  public static centimetersToMeters(centimeters: number): number {
    return centimeters / 100;
  }

  public static metersToCentimeters(meters: number): number {
    return meters * 100;
  }

  public static poundsToKilograms(pounds: number): number {
    return pounds * 0.453592;
  }

  public static kilogramsToPounds(kilograms: number): number {
    return kilograms * 2.20462;
  }
}
