import { Component, input, model, signal } from '@angular/core';
import { CaregiverRole, CaregiverRoles } from '@models';
import { SelectComponent } from '@components/forms/fields/select/select.component';

@Component({
    selector: 'app-caregiver-role-select',
    imports: [
        SelectComponent,
    ],
    templateUrl: './caregiver-role-select.component.html',
    styleUrl: './caregiver-role-select.component.scss'
})
export class CaregiverRoleSelectComponent {
  role = model<CaregiverRole>();
  valid = model<boolean>(false);
  disabled = input<boolean>(false);
  required = input<boolean>(false);
  public options = signal<{value: CaregiverRole, name: string}[]>([
    {value: CaregiverRoles.ADMIN, name: "Admin"},
    {value: CaregiverRoles.THERAPIST, name: "Therapist"},
  ])
}
