<span matDialogTitle>Create Organization</span>

<mat-dialog-content>
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Address</mat-label>
      <input formControlName="address" matInput/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input formControlName="email" matInput/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Phone</mat-label>
      <input formControlName="phone" matInput/>
    </mat-form-field>
    <h2>Profile Picture</h2>
    <div class="file-upload">
      <button (click)="fileInput.click()" color="primary" mat-mini-fab>
        <mat-icon fontSet="material-symbols-outlined">attach_file</mat-icon>
      </button>
      <input #fileInput (change)="onFileSelected($event)" accept="image/*" type="file">
      <span>{{ profilePicture?.name ?? "No profile picture selected" }}</span>
      <button (click)="clearFileSelection()" class="remove-file" color="warn" mat-mini-fab>
        <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
      </button>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="onNoClick()" color="warn" mat-raised-button matDialogClose>
    <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
    Cancel
  </button>

  <button (click)="submit()" [disabled]="formGroup.invalid" color="primary" mat-raised-button>
    Submit
    <mat-icon fontSet="material-symbols-outlined">publish</mat-icon>
  </button>
</mat-dialog-actions>
