import { Component, input, OnChanges, OnInit, signal, SimpleChanges } from '@angular/core';
import { LoadingSpinnerComponent } from '@components/loaders/loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-loadable',
  imports: [
    LoadingSpinnerComponent
  ],
  templateUrl: './loadable.component.html',
  styleUrl: './loadable.component.scss'
})
export class LoadableComponent implements OnInit, OnChanges {
  public readonly isLoading = input(false);
  public readonly minimumDurationMilliseconds = input(0);

  protected readonly showLoadingSpinner = signal(false);

  public ngOnInit() {
    this.showLoadingSpinner.set(this.isLoading());
  }

  public ngOnChanges(changes: SimpleChanges) {
    function hasChanged(key: string) {
      return changes[key].previousValue !== changes[key].currentValue && !changes[key].firstChange;
    }

    if (hasChanged('isLoading')) {
      if (this.isLoading()) {
        this.showLoadingSpinner.set(true);
      } else {
        setTimeout(() => {
          this.showLoadingSpinner.set(false);
        }, this.minimumDurationMilliseconds());
      }
    }
  }
}
