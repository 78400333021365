import { Component, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog';
import { SurveyResponse } from "@models";
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-survey-response-dialog',
    imports: [
        MatButton,
        MatDialogActions,
        MatDialogClose,
        MatDialogContent,
        MatDialogTitle,
        MatIcon,
        MatTooltip
    ],
    templateUrl: './survey-response-dialog.component.html',
    styleUrl: './survey-response-dialog.component.scss'
})
export class SurveyResponseDialogComponent {
  data = inject<{surveyName: string, response: SurveyResponse}>(MAT_DIALOG_DATA);
}
