import { enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '@environment';
import { AppComponent } from '@app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from '@app/app.config';

if (environment.production) {
  enableProdMode();
}

if(!environment.local) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.production ? 'production' : 'development',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", environment.serverURL],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

bootstrapApplication(AppComponent, appConfig);
