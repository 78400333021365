import { inject, Injectable } from '@angular/core';
import { OrganizationID, Patient, PatientCreate, PatientFilters, PatientUpdate, UserID } from '@models';
import { ApiService } from '@services/API/api.service';

@Injectable({
  providedIn: "root"
})
export class PatientService {
  private readonly api = inject(ApiService);

  /**
   * Get a list of patients for an organization
   * @param organizationID The ID of the organization
   * @param page The page number
   * @param pageSize The number of items per page
   * @param filters The filters to apply to the query
   * @param filters.search The search term to filter by
   * @returns The list of patients
   */
  public async getPatients(
    organizationID: OrganizationID,
    page: number,
    pageSize: number,
    filters?: PatientFilters
  ): Promise<{ data: Patient[], total: number }> {
    const result = await this.api.get<{ data: Patient[], total: number }>(`organizations/${organizationID}/patients`, {
      "page": page.toString(),
      "limit": pageSize.toString(),
      "search": filters?.search
    });

    return {
      data: result.data.map(patient => ({
        ...patient,
        date_of_birth: new Date(patient.date_of_birth)
      })),
      total: result.total
    };
  }

  /**
   * Get a patient by ID
   * @param organizationID The ID of the organization
   * @param patientID The ID of the patient
   * @returns The patient
   */
  public async getPatient(organizationID: OrganizationID, patientID: UserID): Promise<Patient> {
    const result = await this.api.get<Patient>(`organizations/${organizationID}/patients/${patientID}`);
    return {
      ...result,
      date_of_birth: new Date(result.date_of_birth)
    };
  }

  public async getCaregiverPatients(
    organizationID: OrganizationID,
    caregiverID: UserID,
    filters?: PatientFilters
  ): Promise<{ data: Patient[], total: number }> {
    const result = await this.api.get<{ data: Patient[], total: number }>(`organizations/${organizationID}/caregivers/${caregiverID}/patients`, {
      "page": filters?.pagination?.page.toString(),
      "limit": filters?.pagination?.pageSize.toString(),
      "search": filters?.search
    });

    return {
      data: result.data.map(patient => ({
        ...patient,
        date_of_birth: new Date(patient.date_of_birth)
      })),
      total: result.total
    };
  }

  /**
   * Create a patient
   * @param organizationID - The ID of the organization
   * @param patient - The patient to create
   */
  public createPatient(organizationID: OrganizationID, patient: PatientCreate): Promise<string> {
    return this.api.post<string>(`organizations/${organizationID}/patients`, patient, { responseType: "text" });
  }

  /**
   * Update a patient
   * @param organizationID - The ID of the organization
   * @param patientID - The ID of the patient
   * @param patient - The patient to update
   */
  public updatePatient(organizationID: OrganizationID, patientID: UserID, patient: PatientUpdate): Promise<string> {
    return this.api.put<string>(`organizations/${organizationID}/patients/${patientID}`, patient, { responseType: "text" });
  }

  /**
   * Delete a patient
   * @param organizationID - The ID of the organization
   * @param patientID - The ID of the patient
   */
  public deletePatient(organizationID: OrganizationID, patientID: UserID): Promise<string> {
    return this.api.delete<string>(`organizations/${organizationID}/patients/${patientID}`, { responseType: "text" });
  }
}
