import { Component, input, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'app-input[type="number"]',
    imports: [
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    templateUrl: './input-numeric.component.html',
    styleUrl: './input-numeric.component.scss'
})
export class InputNumericComponent {
  value = model<number>();
  valid = model<boolean>(false);
  placeholder = model<string>();
  label = model<string>();
  disabled = input<boolean>(false);
  required = input<boolean>(false);
  min = input<number>();
  max = input<number>();
  allow_decimals = input<boolean>(true);
  suffix = input<string>();
}
