import { inject, Injectable } from '@angular/core';
import { StateService } from '@services/state.service';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

/**
 * A service for uploading files to the server.
 * @class
 */
@Injectable({
  providedIn: "root"
})
export class FileUploadService {
  private readonly http = inject(HttpClient);
  private readonly state = inject(StateService);

  private readonly serverURL = `${this.state.fileServerURL}/files`;

  /**
   * Uploads a file to the server.
   * @param file - The file to upload.
   * @param field_name - The field name to use in the form data.
   * @param max_retries - The maximum number of retries to attempt.
   * @returns A promise that resolves when the file is uploaded successfully.
   * @throws {Error} - If the file could not be uploaded after the maximum number of retries.
   */
  public async uploadFile(file: File, field_name: string, max_retries: number = 3): Promise<string> {
    let retries = 0;
    while (retries <= max_retries) {
      const response = await this.tryUploadFile(file, field_name);
      if (response) {
        return response;
      }
      retries++;
    }

    throw new Error(`Failed to upload file ${file.name} after ${max_retries} retries`);
  }

  /**
   * Uploads multiple files to the server at the same time.
   * @param files - The files to upload.
   * @param field_names - The field names to use in the form data.
   * @param max_retries - The maximum number of retries to attempt.
   * @returns A promise that resolves to an array of attachment IDs for the uploaded files.
   * @throws {Error} - If any of the files could not be uploaded after the maximum number of retries.
   */
  public async uploadFiles(files: File[], field_names: string[], max_retries: number = 3): Promise<string[]> {
    const promises = files.map((file, index) => this.uploadFile(file, field_names[index], max_retries));
    return await Promise.all(promises);
  }

  /**
   * Tries to upload a file to the server.
   * @param file - The file to upload.
   * @param field_name - The field name to use in the form data.
   * @returns A promise that resolves to the attachment ID if successful, or null if unsuccessful.
   */
  private async tryUploadFile(file: File, field_name: string): Promise<string | null> {
    const formData = new FormData();
    formData.append(field_name, file);

    try {
      const response = await firstValueFrom(this.http.post<{ attachment_id: string }>(this.serverURL, formData));
      return response.attachment_id;
    } catch (error) {
      return null;
    }
  }
}
