import { Component, input, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'app-input[type="email"]',
    imports: [
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    templateUrl: './input-email.component.html',
    styleUrl: './input-email.component.scss'
})
export class InputEmailComponent {
  email = model<string>();
  valid = model<boolean>(false);
  placeholder = model<string>("john.doe@example.com");
  label = model<string>("Email");
  disabled = input<boolean>(false);
  required = input<boolean>(false);
  minLength = input<number>(0);
  maxLength = input<number>(256);
}
