import { inject, Injectable } from '@angular/core';
import { Organization, OrganizationCreation, OrganizationFilters } from '@models';
import { ApiService } from '@services/API/api.service';
import { FileUploadService } from '@services/API/file-upload.service';

@Injectable({
  providedIn: "root"
})
export class OrganizationService {
  private readonly api = inject(ApiService);
  private readonly fileUploadService = inject(FileUploadService);

  public getOrganizations(
    page: number,
    limit: number,
    params?: OrganizationFilters
  ): Promise<{ data: Organization[], total: number }> {
    return this.api.get<{ data: Organization[], total: number }>("organizations", {
      "page": page.toString(),
      "limit": limit.toString(),
      "search": params?.search
    });
  }

  public getOrganization(organizationId: string): Promise<Organization> {
    return this.api.get<Organization>(`organizations/${organizationId}`);
  }

  public async createOrganization(organization: OrganizationCreation, profile_picture?: File | null): Promise<void> {
    if (profile_picture !== undefined && profile_picture !== null) {
      organization.profile_picture = await this.fileUploadService.uploadFile(profile_picture, "upload");
    }

    await this.api.post<string>("organizations", organization, { responseType: "text" });
  }

  public async updateOrganization(organizationId: string, organization: Organization, profile_picture?: File | null): Promise<void> {
    if (profile_picture !== undefined && profile_picture !== null) {
      organization.profile_picture = await this.fileUploadService.uploadFile(profile_picture, "upload");
    }

    await this.api.put<string>(`organizations/${organizationId}`, organization, { responseType: "text" });
  }

  public deleteOrganization(organizationId: string): Promise<string> {
    return this.api.delete<string>(`organizations/${organizationId}`, { responseType: "text" });
  }
}
