import { Component, input, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';

@Component({
    selector: 'app-patient-date-of-birth-input',
    imports: [
        FormsModule,
        MatInputModule,
        MatDatepickerModule,
    ],
    providers: [
        provideNativeDateAdapter()
    ],
    templateUrl: './patient-date-of-birth-input.component.html',
    styleUrl: './patient-date-of-birth-input.component.scss'
})
export class PatientDateOfBirthInputComponent {
  dateOfBirth = model<Date>();
  valid = model<boolean>(false);
  disabled = input<boolean>(false);
  required = input<boolean>(false);
}
