@if (initialized) {
  <h1 mat-dialog-title>{{ exercise.name }}</h1>
  <div mat-dialog-content>
    @if (exercise.video) {
      <section>
        <h2>Video</h2>
        <app-youtube-video [src]="exercise.video"></app-youtube-video>
      </section>
    }
    @if (exercise.thumbnail) {
      <section>
        <h2>Thumbnail</h2>
        <img [attr.src]="exercise.thumbnail | secure | async"
          alt="File type not supported"
          class="mat-mdc-dialog-image"
          loading="lazy"/>
        </section>
      }
      @if (exercise.preview) {
        <section>
          <h2>Preview</h2>
          <img [attr.src]="exercise.preview | secure | async"
            alt="File type not supported"
            class="mat-mdc-dialog-image"
            loading="lazy"/>
          </section>
        }
        <section>
          <h2>Short Description</h2>
          <p>{{ exercise.short_description || "N/A" }}</p>
        </section>
        <section>
          <h2>Long Description</h2>
          <p>{{ exercise.long_description || "N/A" }}</p>
        </section>
        <section>
          <h2>Motion Tracking</h2>
          <p>Motion is trackable via:</p>
          <mat-chip-set>
            @if (exercise.reps_enabled) {
              <mat-chip color="primary">Repetitions</mat-chip>
            }
            @if (exercise.rom_enabled) {
              <mat-chip color="primary">ROM</mat-chip>
            }
            <mat-chip color="primary">Time</mat-chip>
          </mat-chip-set>
        </section>
        <section>
          <h2>Details</h2>
          <p>Position: {{ exercise.exercise_position }}</p>
          <p>Camera View: {{ exercise.camera_view }}</p>
        </section>
        <section>
          <h2>Equipment</h2>
          <mat-chip-set>
            @for (equipment of exercise.equipment; track equipment) {
              <mat-chip color="primary">{{ equipment.equipment_name }}</mat-chip>
            }
          </mat-chip-set>
        </section>
        <section>
          <h2>Tracked Joints</h2>
          <mat-chip-set>
            @for (joint of exercise.joints; track joint) {
              <mat-chip color="primary">{{ joint.joint_name }}</mat-chip>
            }
          </mat-chip-set>
        </section>
        <section>
          <h2>Default Values</h2>
          <p>Default sets: {{ exercise.default_sets }}</p>
          @if (exercise.default_reps != undefined) {
            <p>Default reps: {{ exercise.default_reps }}</p>
          }
          @if (exercise.default_time != undefined) {
            <p>Default time: {{ exercise.default_time }}</p>
          }
        </section>
        @if (exercise.reps_enabled) {
          <section>
            <h2>Default Rep Angle Thresholds</h2>
            <mat-list role="list">
              @for (joint of exercise.joints; track joint) {
                <mat-list-item>{{ joint.joint_name }}:
                  [{{ joint.default_min_rep_threshold }}° - {{ joint.default_max_rep_threshold }}°]
                </mat-list-item>
              }
            </mat-list>
          </section>
        }
        @if (exercise.rom_enabled) {
          <section>
            <h2>Default ROM Angle Thresholds</h2>
            <mat-list role="list">
              @for (joint of exercise.joints; track joint) {
                <mat-list-item>{{ joint.joint_name }}:
                  [{{ joint.default_min_rom_threshold }}° - {{ joint.default_max_rom_threshold }}°]
                </mat-list-item>
              }
            </mat-list>
          </section>
        }
      </div>
      <div mat-dialog-actions>
        <button aria-label="Close the dialog" color="warn" mat-dialog-close mat-flat-button matTooltip="Close">
          <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
          Close
        </button>
      </div>
    } @else {
      <app-loading-spinner />
    }

