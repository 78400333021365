import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { Survey } from '@models/Surveys';

@Component({
    selector: 'app-survey-info-dialog',
    imports: [MatButtonModule, MatChipsModule, MatDialogModule, MatIconModule, MatListModule, MatTooltipModule, MatCardModule],
    templateUrl: './survey-info-dialog.component.html',
    styleUrl: './survey-info-dialog.component.scss'
})
export class SurveyInfoDialogComponent {
  survey = inject<Survey>(MAT_DIALOG_DATA);

}
