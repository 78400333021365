import { HttpEventType, HttpInterceptorFn } from '@angular/common/http';
import { tap } from 'rxjs';
import { LoggerService } from '@services/misc/logger.service';
import { inject } from '@angular/core';

export const loggerInterceptor: HttpInterceptorFn = (req, next) => {
  const logger = inject(LoggerService);

  return next(req).pipe(tap(event => {
    if (event.type !== HttpEventType.Response) {
      return;
    }

    if (!event.ok) {
      logger.error(`HTTP error. Target URL: ${event.url}, status: ${event.status} - ${event.statusText}`);
    }
  }));
};
