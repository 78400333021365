import { inject, Injectable } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly overlayContainer = inject(OverlayContainer);

  get defaultTheme(): 'light' | 'dark' | null {
    if (localStorage.getItem('theme') === 'dark' || localStorage.getItem('theme') === 'light') {
      return localStorage.getItem('theme') as 'light' | 'dark';
    }

    return null;
  }

  set defaultTheme(theme: 'light' | 'dark') {
    localStorage.setItem('theme', theme);
  }

  set theme(theme: 'light' | 'dark') {
    const isDarkTheme = theme === 'dark';

    const body = document.body;
    const overlayContainerElement = this.overlayContainer.getContainerElement();

    body.classList.toggle('dark-theme', isDarkTheme);
    overlayContainerElement.classList.toggle('dark-theme', isDarkTheme);

    this.defaultTheme = theme;
  }

  toggleTheme(isDarkTheme: boolean) {
    const body = document.body;
    const overlayContainerElement = this.overlayContainer.getContainerElement();

    body.classList.toggle('dark-theme', isDarkTheme);
    overlayContainerElement.classList.toggle('dark-theme', isDarkTheme);

    if (isDarkTheme) {
      this.defaultTheme = 'dark';
    } else {
      this.defaultTheme = 'light';
    }
  }

  initialize() {
    if (this.defaultTheme === null) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.defaultTheme = 'dark';
        this.toggleTheme(true)
      } else {
        this.defaultTheme = 'light';
        this.toggleTheme(false)
      }
    } else {
      this.toggleTheme(this.defaultTheme === 'dark');
    }
  }
}
