import { Routes } from '@angular/router';
import { environment } from '@environment';
import { authenticatedGuard, unauthenticatedGuard } from './guards';

export const routes: Routes = [
  {
    path: "",
    title: `${environment.title}`,
    loadComponent: () =>
      import("@screens/home/home.component").then(
        (m) => m.HomeComponent
      ),
    canActivate: [unauthenticatedGuard]
  },
  {
    path: "dashboard",
    title: `Dashboard | ${environment.title}`,
    loadComponent: () =>
      import("@screens/dashboard/dashboard.component").then(
        (m) => m.DashboardComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/patients/:patient_id/dashboard",
    title: `Dashboard | ${environment.title}`,
    loadComponent: () =>
      import("@screens/dashboard/dashboard.component").then(
        (m) => m.DashboardComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "capture-configuration",
    title: `Configure Capture | ${environment.title}`,
    loadComponent: () =>
      import("@screens/capture/capture.component").then(
        (m) => m.CaptureComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations",
    title: `Organizations | ${environment.title}`,
    loadComponent: () =>
      import("@screens/organizations/organization-list/organization-list.component").then(
        (m) => m.OrganizationListComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id",
    title: `Organization Details | ${environment.title}`,
    loadComponent: () =>
      import("@screens/organizations/organization-details/organization-details.component").then(
        (m) => m.OrganizationDetailsComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/patients/:patient_id/programs/:program_id",
    title: `Program Info | ${environment.title}`,
    loadComponent: () =>
      import("@screens/programs/program-info/program-info.component").then(
        (m) => m.ProgramInfoComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/caregivers/:caregiver_id/assign-programs",
    title: `Program Builder | ${environment.title}`,
    loadComponent: () =>
      import("@screens/programs/program-builder/program-builder.component").then(
        (m) => m.ProgramBuilderComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/caregivers/:caregiver_id/program-templates",
    title: `Program Templates | ${environment.title}`,
    loadComponent: () =>
      import("@screens/programs/program-templates/program-templates.component").then(
        (m) => m.ProgramTemplatesComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/caregivers/:caregiver_id/program-templates/build",
    title: `Program Template Builder | ${environment.title}`,
    loadComponent: () =>
      import("@screens/programs/program-template-builder/program-template-builder.component").then(
        (m) => m.ProgramTemplateBuilderComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/caregivers/:caregiver_id/program-templates/:program_id/info",
    title: `Program Template Info | ${environment.title}`,
    loadComponent: () =>
      import("@screens/programs/program-template-builder/program-template-builder.component").then(
        (m) => m.ProgramTemplateBuilderComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/patients/:patient_id/programs/:program_id/run",
    title: `Run Program | ${environment.title}`,
    loadComponent: () =>
      import("@screens/programs/program-runner/program-runner.component").then(
        (m) => m.ProgramRunnerComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/patients/:patient_id/programs/:program_id/surveys/:survey_id/run",
    title: `Complete Survey | ${environment.title}`,
    loadComponent: () =>
      import("@screens/surveys/survey-runner/survey-runner.component").then(
        (m) => m.SurveyRunnerComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "exercises",
    title: `Exercises | ${environment.title}`,
    loadComponent: () =>
      import("@screens/exercises/exercise-list/exercise-list.component").then(
        (m) => m.ExerciseListComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "exercises/create",
    title: `Create Exercise | ${environment.title}`,
    loadComponent: () =>
      import("@screens/exercises/create-exercise-screen/create-exercise-screen.component").then(
        (m) => m.CreateExerciseScreenComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "functional-tests",
    title: `Functional Tests | ${environment.title}`,
    loadComponent: () =>
      import("@screens/functional-tests/functional-test-list/functional-test-list.component").then(
        (m) => m.FunctionalTestListComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "functional-tests/create",
    title: `Create Functional Test | ${environment.title}`,
    loadComponent: () =>
      import("@screens/functional-tests/create-functional-test-screen/create-functional-test-screen.component").then(
        (m) => m.CreateFunctionalTestScreenComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "surveys",
    title: `Surveys | ${environment.title}`,
    loadComponent: () =>
      import("@screens/surveys/survey-list/survey-list.component").then(
        (m) => m.SurveyListComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "surveys/build",
    title: `Survey Builder | ${environment.title}`,
    loadComponent: () =>
      import("@screens/surveys/survey-builder/survey-builder.component").then(
        (m) => m.SurveyBuilderComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "surveys/:survey_id/build",
    title: `Survey Builder | ${environment.title}`,
    loadComponent: () =>
      import("@screens/surveys/survey-builder/survey-builder.component").then(
        (m) => m.SurveyBuilderComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/surveys/build",
    title: `Survey Builder | ${environment.title}`,
    loadComponent: () =>
      import("@screens/surveys/survey-builder/survey-builder.component").then(
        (m) => m.SurveyBuilderComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/surveys/:survey_id/build",
    title: `Survey Builder | ${environment.title}`,
    loadComponent: () =>
      import("@screens/surveys/survey-builder/survey-builder.component").then(
        (m) => m.SurveyBuilderComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/caregivers/:caregiver_id/surveys/build",
    title: `Survey Builder | ${environment.title}`,
    loadComponent: () =>
      import("@screens/surveys/survey-builder/survey-builder.component").then(
        (m) => m.SurveyBuilderComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/caregivers/:caregiver_id/surveys/:survey_id/build",
    title: `Survey Builder | ${environment.title}`,
    loadComponent: () =>
      import("@screens/surveys/survey-builder/survey-builder.component").then(
        (m) => m.SurveyBuilderComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/users/:user_id/profile",
    title: `Profile | ${environment.title}`,
    loadComponent: () =>
      import("@screens/profiles/profile/profile.component").then(
        (m) => m.ProfileComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "organizations/:organization_id/patients/:patient_id/profile",
    title: `Profile | ${environment.title}`,
    loadComponent: () =>
      import("@screens/profiles/patient-profile/patient-profile.component").then(
        (m) => m.PatientProfileComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "settings",
    title: `Settings | ${environment.title}`,
    loadComponent: () =>
      import("@screens/settings/settings.component").then(
        (m) => m.SettingsComponent
      ),
    canActivate: [authenticatedGuard]
  },
  {
    path: "**",
    redirectTo: ""
  }
];
