import { AuthConfig } from '@auth0/auth0-angular';
import { ServerConfig } from '@models/server-config';

export const AuthConfigs: Record<ServerConfig, AuthConfig> = {
  'local': {
    domain: 'dev-14up8l6481m4vo10.us.auth0.com',
    clientId: '0xrvcLTL646lK9XzUAqEuyI5BkEcZtWf',
    authorizationParams: {
      audience: 'https://fitpal.illumica.com',
      redirect_uri: window.location.origin,
    },
    httpInterceptor: {
      allowedList: ['http://localhost:8080/*'],
    },
    errorPath: '/callback',
  },
  'development': {
    domain: 'fitpal-staging.us.auth0.com',
    clientId: 'HOxdnb5KNVfXmaKEfXTBp3aL4pvZkiYI',
    authorizationParams: {
      audience: 'https://fitpal.illumica.ai',
      redirect_uri: window.location.origin,
    },
    httpInterceptor: {
      allowedList: ['https://dev.api.illumica.co/*'],
    },
    errorPath: '/callback',
  },
  'production': {
    domain: 'fitpal.us.auth0.com',
    clientId: 'EzL6xQuWtlbvqG0ZTCPM5hZYBkphr0RA',
    authorizationParams: {
      audience: 'https://api.illumica.co/',
      redirect_uri: window.location.origin,
    },
    httpInterceptor: {
      allowedList: ['https://api.illumica.co/*'],
    },
    errorPath: '/callback',
  },
}
