import { Component, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'app-login-button',
    imports: [MatButtonModule],
    templateUrl: './login-button.component.html',
    styleUrl: './login-button.component.scss'
})
export class LoginButtonComponent {
  private auth = inject(AuthService);

  public readonly color = input<'primary' | 'accent' | 'warn' | null>(null);
  public readonly disabled = input(false);

  handleLogin(): void {
    this.auth.loginWithRedirect({
      appState: {
        target: '/',
      },
    });
  }
}
