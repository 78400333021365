import { Component, input, model } from '@angular/core';
import { InputNumericComponent } from '@components/forms/fields/input-numeric/input-numeric.component';

@Component({
    selector: "app-patient-height-input",
    imports: [
        InputNumericComponent
    ],
    templateUrl: "./patient-height-input.component.html",
    styleUrl: "./patient-height-input.component.scss"
})
export class PatientHeightInputComponent {
  height = model<number>();
  valid = model<boolean>(false);
  units = model<'in' | 'cm'>('cm');
  disabled = input<boolean>(false);
  required = input<boolean>(false);
}
