import { inject, Injectable } from '@angular/core';
import { Exercise, ExerciseCreation, ExerciseEquipment, ExerciseFilters, ExerciseID, ExerciseJoint } from '@models';
import { ApiService } from '@services/API/api.service';

/**
 * A service for fetching and creating exercises.
 * @class
 */
@Injectable({
  providedIn: "root"
})
export class ExerciseService {
  private readonly api = inject(ApiService);

  /**
   * Fetch an exercise by its ID.
   * @param exercise_id - The ID of the exercise to fetch.
   * @returns A promise that resolves to the exercise.
   * @throws {Error} If the request fails.
   */
  public getExerciseByID(exercise_id: ExerciseID): Promise<Exercise> {
    return this.api.get<Exercise>(`exercises/${exercise_id}`);
  }

  public getEquipmentByExerciseID(exercise_id: ExerciseID): Promise<ExerciseEquipment[]> {
    return this.api.get<ExerciseEquipment[]>(`exercises/${exercise_id}/equipment`);
  }

  public getJointsByExerciseID(exercise_id: ExerciseID): Promise<ExerciseJoint[]> {
    return this.api.get<ExerciseJoint[]>(`exercises/${exercise_id}/joints`);
  }

  /**
   * Fetches exercises with optional query parameters.
   * @param filters - The optional query parameters.
   * @returns A promise that resolves to an object containing an array of exercises and the total number of exercises.
   */
  public getExercises(filters?: ExerciseFilters): Promise<{
    data: Exercise[],
    total: number
  }> {
    return this.api.get<{ data: Exercise[], total: number }>("exercises", {
      "page": filters?.pageParams?.page.toString(),
      "limit": filters?.pageParams?.limit.toString(),
      "search": filters?.search,
      "camera_view": filters?.camera_view,
      "position": filters?.position,
      "rom_enabled": filters?.rom_enabled?.toString(),
      "reps_enabled": filters?.reps_enabled?.toString()
    });
  }

  /**
   * Creates a new exercise.
   * @param exercise - The exercise to create.
   * @returns A promise that resolves to a boolean indicating whether the exercise was created successfully.
   */
  public createExercise(exercise: ExerciseCreation): Promise<string> {
    return this.api.post<string>("exercises", exercise, { responseType: "text" });
  }


  public updateExercise(exercise_id: ExerciseID, exercise: ExerciseCreation): Promise<string> {
    return this.api.put<string>(`exercises/${exercise_id}`, exercise, { responseType: "text" });
  }

  public deleteExercise(exercise_id: ExerciseID): Promise<string> {
    return this.api.delete<string>(`exercises/${exercise_id}`, { responseType: "text" });
  }
}
