import { Component, computed, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CaregiverRole } from '@models';
import { CaregiverService } from '@services/API/caregiver.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InputEmailComponent } from '@components/forms/fields/input-email/input-email.component';
import { FirstNameInputComponent } from '@components/forms/fields/first-name-input/first-name-input.component';
import { LastNameInputComponent } from '@components/forms/fields/last-name-input/last-name-input.component';
import {
  CaregiverRoleSelectComponent
} from '@components/forms/fields/caregiver-role-select/caregiver-role-select.component';
import * as Sentry from '@sentry/angular';

@Component({
    selector: 'app-create-caregiver-dialog',
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        InputEmailComponent,
        FirstNameInputComponent,
        LastNameInputComponent,
        CaregiverRoleSelectComponent
    ],
    templateUrl: './create-caregiver-dialog.component.html',
    styleUrl: './create-caregiver-dialog.component.scss'
})
export class CreateCaregiverDialogComponent {
  protected readonly data = inject(MAT_DIALOG_DATA);


  email = signal<string | undefined>(undefined);
  emailValid = signal<boolean>(false);
  firstName = signal<string | undefined>(undefined);
  firstNameValid = signal<boolean>(false);
  lastName = signal<string | undefined>(undefined);
  lastNameValid = signal<boolean>(false);
  role = signal<CaregiverRole | undefined>(undefined);
  roleValid = signal<boolean>(false);

  formValid = computed<boolean>(() => {
    return (
      this.emailValid() &&
      this.firstNameValid() &&
      this.lastNameValid() &&
      this.roleValid()
    )
  })

  private userService = inject(CaregiverService);
  private dialogRef = inject(MatDialogRef<CreateCaregiverDialogComponent>);

  onNoClick(): void {
    this.dialogRef.close();
  }

  async submit() {
    if (!this.formValid()) {
      return;
    }

    try {
      await this.userService.createCaregiver(this.data.organizationID, {
        first_name: this.firstName()!,
        last_name: this.lastName()!,
        email: this.email()!,
        role: this.role()!,
        profile_picture: null,
      });
      this.dialogRef.close({success: true, error: null});
    } catch (error) {
      Sentry.captureException(error);
      this.dialogRef.close({success: false, error});
    }
  }
}
