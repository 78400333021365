import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { ThemeService } from "@services/theme.service";
import { UserRoles } from "@models";
import { StateService } from "@services/state.service";
import { RouterService } from "@services/router.service";
import { environment } from "@environment";
import { ServerConfigService } from "@services/server-config.service";
import { ServerConfig } from "@models/server-config";
import { SelectComponent } from "@components/forms/fields/select/select.component";
import { LoginButtonComponent } from "@components/buttons/login-button/login-button.component";
import { ProfileButtonComponent } from "@components/buttons/profile-button/profile-button.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { NavigationEnd, Router, RouterLink, RouterOutlet } from "@angular/router";
import { MatSidenavModule } from "@angular/material/sidenav";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { toSignal } from "@angular/core/rxjs-interop";
import { MatIconModule } from "@angular/material/icon";
import { LoadableComponent } from "@components/loaders/loadable/loadable.component";
import { DialogService } from "./services/dialog.service";
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrl: "./app.component.scss",
  imports: [
    MatSidenavModule,
    MatListModule,
    MatDividerModule,
    RouterLink,
    MatToolbarModule,
    ProfileButtonComponent,
    LoginButtonComponent,
    RouterOutlet,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    LoadableComponent
  ]
})
export class AppComponent implements OnInit {
  protected readonly dialogService = inject(DialogService);
  protected readonly authService = inject(AuthService);
  protected readonly themeService = inject(ThemeService);
  protected readonly stateService = inject(StateService);
  protected readonly routerService = inject(RouterService);
  protected readonly router = inject(Router);

  private readonly _isSidenavOpen = signal(false);
  public readonly isSidenavOpen = this._isSidenavOpen.asReadonly();

  private readonly _isAuthLoading = toSignal(this.authService.isLoading$);
  protected readonly isAuthLoading = computed(() => this._isAuthLoading() === true);

  private readonly _isAuthenticated = toSignal(this.authService.isAuthenticated$);
  protected readonly isAuthenticated = computed(() => this._isAuthenticated() === true);

  protected role = toSignal(this.stateService.getRole());
  protected routes = computed(() => {
    switch (this.role()) {
      case UserRoles.SUPER_ADMIN:
        return [
          { path: this.routerService.routeDashboard(), title: "Dashboard", icon: "dashboard" },
          { path: this.routerService.routeOrganizations(), title: "Organizations", icon: "groups" },
          { path: this.routerService.routeExercises(), title: "Exercises", icon: "exercise" },
          { path: this.routerService.routeFunctionalTests(), title: "Functional Tests", icon: "vital_signs" },
          { path: this.routerService.routeSurveys(), title: "Surveys", icon: "assignment" },
          { path: this.routerService.routeCaptureConfiguration(), title: "Configure Capture", icon: "videocam" }
        ];
      case UserRoles.ADMIN:
        return [
          { path: this.routerService.routeDashboard(), title: "Dashboard", icon: "dashboard" },
          { path: this.routerService.routeOrganizationDetails(), title: "My Organization", icon: "group" },
          { path: this.routerService.routeProgramTemplates(), title: "HEP Templates", icon: "file_copy" },
          { path: this.routerService.routeExercises(), title: "Exercises", icon: "exercise" },
          { path: this.routerService.routeFunctionalTests(), title: "Functional Tests", icon: "vital_signs" },
          { path: this.routerService.routeSurveys(), title: "Surveys", icon: "assignment" }
        ];
      case UserRoles.THERAPIST:
        return [
          { path: this.routerService.routeDashboard(), title: "Dashboard", icon: "dashboard" },
          { path: this.routerService.routeOrganizationDetails(), title: "My Organization", icon: "group" },
          { path: this.routerService.routeProgramTemplates(), title: "HEP Templates", icon: "file_copy" },
          { path: this.routerService.routeExercises(), title: "Exercises", icon: "exercise" },
          { path: this.routerService.routeFunctionalTests(), title: "Functional Tests", icon: "vital_signs" },
          { path: this.routerService.routeSurveys(), title: "Surveys", icon: "assignment" }
        ];
      case UserRoles.PATIENT:
        return [
          { path: this.routerService.routeDashboard(), title: "Dashboard", icon: "dashboard" }
        ];
      default:
        return [];
    }
  });

  protected isLocal = signal(environment.local);

  public async ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._isSidenavOpen.set(false);
      }
    });

    this.themeService.toggleTheme(true);

    await this.routerService.initialize();
  }

  public openSidenav() {
    this._isSidenavOpen.set(true);
  }

  public closeSidenav() {
    this._isSidenavOpen.set(false);
  }

  public toggleSidenav() {
    if (this.isSidenavOpen()) {
      this.closeSidenav();
    } else {
      this.openSidenav();
    }
  }

  protected openSettingsDialog() {
    return this.dialogService.openDialog(SettingsDialogComponent);
  }
}

@Component({
  template: `
    <h1 mat-dialog-title>Developer Settings</h1>
    <mat-dialog-content>
      <app-select
        label="API Selection"
        [(value)]="serverConfig"
        [options]="serverConfigs"
      />
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button (click)="close()">Close</button>
      <button mat-raised-button (click)="submit()" color="primary">Submit</button>
    </mat-dialog-actions>
  `,
  imports: [
    MatButtonModule,
    SelectComponent,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions
  ]
})
export class SettingsDialogComponent implements OnInit {
  protected readonly dialogRef = inject(MatDialogRef<SettingsDialogComponent>);
  protected readonly serverConfigService = inject(ServerConfigService);
  protected readonly serverConfigs: ServerConfig[] = ["local", "development", "production"];

  protected serverConfig = signal<ServerConfig>("local");

  public async ngOnInit() {
    this.serverConfigService.getServerConfig().then(serverConfig => {
      this.serverConfig.set(serverConfig);
    });
  }

  protected close() {
    this.dialogRef.close();
  }

  protected async submit() {
    const serverConfig = this.serverConfig();
    const currentServerConfig = await this.serverConfigService.getServerConfig();
    if (serverConfig && serverConfig !== currentServerConfig) {
      await this.serverConfigService.setServerConfig(serverConfig);
    }

    this.dialogRef.close();
  }
}
