<app-select
  label="Sex"
  placeholder="Select a Sex"
  [value]="sex()"
  (valueChange)="sex.set($event)"
  [valid]="valid()"
  (validChange)="valid.set($event)"
  [options]="options()"
  [disabled]="disabled()"
  [required]="required()"
></app-select>
