import { inject, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'secure',
  standalone: true
})
export class SecurePipe implements PipeTransform {
  protected readonly http = inject(HttpClient);
  protected readonly sanitizer = inject(DomSanitizer);

  transform(url: string | null | undefined): Observable<SafeUrl> {
    if (!url) {
      return new Observable<SafeUrl>();
    }

    return this.http
      .get(url, {responseType: 'blob'})
      .pipe(
        map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val)))
      );
  }
}
