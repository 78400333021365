import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FunctionalTest } from '@models';
import { MatCardModule } from '@angular/material/card';
import { SecurePipe } from '@app/pipes/secure.pipe';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { StateService } from '@services/state.service';
import { FunctionalTestService } from '@services/API/functional-test.service';
import { LoadingSpinnerComponent } from '@components/loaders/loading-spinner/loading-spinner.component';
import { YoutubeVideoComponent } from '@components/youtube-video/youtube-video.component';

@Component({
    selector: 'app-functional-test-info-dialog',
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TextFieldModule,
        MatDialogModule,
        MatCardModule,
        SecurePipe,
        MatChipsModule,
        MatListModule,
        MatIconModule,
        LoadingSpinnerComponent,
        YoutubeVideoComponent
    ],
    templateUrl: './functional-test-info-dialog.component.html',
    styleUrl: './functional-test-info-dialog.component.scss'
})
export class FunctionalTestInfoDialogComponent implements OnInit {
  exercise = inject<FunctionalTest>(MAT_DIALOG_DATA);
  private state = inject(StateService);
  private functionalTestService = inject(FunctionalTestService);


  protected initialized = false;

  async ngOnInit() {
    if (!this.exercise.joints) {
      this.exercise.joints = await this.functionalTestService.getJointsByFunctionalTestID(this.exercise.id);
    }
    if (!this.exercise.equipment) {
      this.exercise.equipment = await this.functionalTestService.getEquipmentByFunctionalTestID(this.exercise.id);
    }
    if (this.exercise.thumbnail && !this.exercise.thumbnail.startsWith('http')) {
      this.exercise.thumbnail = `${this.state.fileServerURL}/files/${this.exercise.thumbnail}`;
    }
    if (this.exercise.video && !this.exercise.video.startsWith('http')) {
      this.exercise.video = `${this.state.fileServerURL}/files/${this.exercise.video}`;
    }
    if (this.exercise.preview && !this.exercise.preview.startsWith('http')) {
      this.exercise.preview = `${this.state.fileServerURL}/files/${this.exercise.preview}`;
    }
    this.initialized = true;
  }
}
