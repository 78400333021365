import { HttpEventType, HttpInterceptorFn } from '@angular/common/http';
import { tap } from 'rxjs';
import * as Sentry from '@sentry/angular';

export const sentryErrorHandlerInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(tap(event => {
    if (event.type !== HttpEventType.Response) {
      return;
    }

    if (!event.ok) {
      Sentry.captureMessage(`HTTP error. Target URL: ${event.url}, status: ${event.status} - ${event.statusText}`, 'error');
    }
  }));
};
