import { Component, input, model } from '@angular/core';
import { InputStringComponent } from '@components/forms/fields/input-string/input-string.component';

@Component({
    selector: 'app-last-name-input',
    imports: [
        InputStringComponent
    ],
    templateUrl: './last-name-input.component.html',
    styleUrl: './last-name-input.component.scss'
})
export class LastNameInputComponent {
  lastName = model<string>();
  valid = model<boolean>(false);
  disabled = input<boolean>(false);
  required = input<boolean>(false);
}
