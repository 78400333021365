<app-input
  type="text"
  [value]="firstName()"
  (valueChange)="firstName.set($event)"
  [valid]="valid()"
  (validChange)="valid.set($event)"
  [minLength]="0"
  [maxLength]="64"
  [disabled]="disabled()"
  [required]="required()"
  placeholder="First Name"
  label="First Name"
/>
