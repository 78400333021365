import { Component, input, model } from '@angular/core';
import { InputNumericComponent } from '@components/forms/fields/input-numeric/input-numeric.component';

@Component({
    selector: 'app-patient-weight-input',
    imports: [
        InputNumericComponent
    ],
    templateUrl: './patient-weight-input.component.html',
    styleUrl: './patient-weight-input.component.scss'
})
export class PatientWeightInputComponent {
  weight = model<number>();
  valid = model<boolean>(false);
  units = model<'kg' | 'lbs'>('kg');
  disabled = input<boolean>(false);
  required = input<boolean>(false);
}
