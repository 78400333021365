<img [matMenuTriggerFor]="menu" [src]="profilePicture()" alt="Profile" />

<mat-menu #menu="matMenu">
  <button ariaCurrentWhenActive="page" mat-menu-item [routerLink]="profileRoute()" routerLinkActive="active">
    <mat-icon fontSet="material-symbols-outlined">account_circle</mat-icon>
    Profile
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item [routerLink]="settingsRoute">
    <mat-icon fontSet="material-symbols-outlined">settings</mat-icon>
    Settings
  </button>
  <mat-divider></mat-divider>
  <button (click)="logout()" mat-menu-item>
    <mat-icon fontSet="material-symbols-outlined">logout</mat-icon>
    Logout
  </button>
</mat-menu>
