<h1 mat-dialog-title>{{ data.surveyName }}</h1>
<div mat-dialog-content>
  @for(answer of data.response.answers; track answer.id) {
    <h2 class="question">Question: {{answer.questionPrompt}}</h2>
    <p class="answer">Response: {{answer.value}}</p>
  }
  @if(data.response.answers.length === 0) {
    <p>No responses found...</p>
  }
</div>

<div mat-dialog-actions>
  <button aria-label="Close the dialog" color="warn" mat-dialog-close mat-flat-button matTooltip="Close">
    <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
    Close
  </button>
</div>
