<app-loadable [isLoading]="isAuthLoading()" [minimumDurationMilliseconds]="1500">
  <mat-sidenav-container (backdropClick)="closeSidenav()">
    <mat-sidenav [opened]="isSidenavOpen()" mode="side">
      <div class="sidenav-actions">
        <button (click)="closeSidenav()" mat-icon-button type="button">
          <mat-icon aria-hidden="false" aria-label="Close sidenav" fontSet="material-symbols-outlined">close</mat-icon>
        </button>
      </div>
      <div class="sidenav-header">
        <img alt="Illumica AI" src="assets/images/illumica.ai.png" width="100" />
      </div>
      <mat-nav-list>
        @for (route of routes(); track route) {
          <mat-divider></mat-divider>
          <mat-list-item [routerLink]="route.path">
            <mat-icon matListItemIcon fontSet="material-symbols-outlined">{{route.icon}}</mat-icon>
            <p matListItemTitle>{{ route.title }}</p>
          </mat-list-item>
        }
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar>
        @if (isAuthenticated()) {
          <button (click)="toggleSidenav()" mat-icon-button type="button">
            <mat-icon fontSet="material-symbols-outlined">menu</mat-icon>
          </button>
          <div class="spacer"></div>
          <app-profile-button></app-profile-button>
        } @else {
          <h1>FitPal</h1>
          <div class="spacer"></div>
          <app-login-button color="primary"></app-login-button>
        }
        @if (isLocal()) {
          <button mat-icon-button (click)="openSettingsDialog()">
            <mat-icon fontSet="material-symbols-outlined">settings</mat-icon>
          </button>
        }
      </mat-toolbar>
      <main>
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-loadable>
