<app-input
  type="number"
  [value]="height()"
  (valueChange)="height.set($event)"
  [valid]="valid()"
  (validChange)="valid.set($event)"
  [min]="0"
  [max]="1000"
  [disabled]="disabled()"
  [required]="required()"
  placeholder="Height"
  label="Height"
  [suffix]="units()"
  />
