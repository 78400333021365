import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  private preferencesSubject = new BehaviorSubject<any>(this.loadPreferences());
  preferences$ = this.preferencesSubject.asObservable();

  constructor() {
  }

  savePreferences(preferences: any): void {
    localStorage.setItem('userPreferences', JSON.stringify(preferences));
    this.preferencesSubject.next(preferences);
  }

  getPreference(key: string): any {
    const preferences = this.loadPreferences();
    return preferences[key];
  }

  setPreference(key: string, value: any): void {
    const preferences = this.loadPreferences();
    preferences[key] = value;
    this.savePreferences(preferences);
  }

  private loadPreferences(): any {
    const preferences = localStorage.getItem('userPreferences');
    return preferences ? JSON.parse(preferences) : {};
  }
}
