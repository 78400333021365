<mat-form-field>
  @if (label()) {
    <mat-label>{{ label() }}</mat-label>
  }
  <input
    #control="ngModel"
    matInput
    type="email"
    [placeholder]="placeholder()"
    [(ngModel)]="email"
    (ngModelChange)="valid.set(control.errors === null)"
    [minLength]="minLength()"
    [maxLength]="maxLength()"
    [required]="required()"
    [disabled]="disabled()" />
  <mat-hint>{{ email()?.length ?? 0 }} / {{ maxLength() }}</mat-hint>
  @if (control.errors && control.errors['minLength']) {
    <mat-error>Must be more than {{ minLength() }} characters.</mat-error>
  }
  @if (control.errors && control.errors['maxLength']) {
    <mat-error>Must be less than {{ maxLength() }} characters.</mat-error>
  }
  @if (control.errors && control.errors['required']) {
    <mat-error>Required.</mat-error>
  }
</mat-form-field>
