import { Component, input, model } from '@angular/core';
import { InputStringComponent } from '@components/forms/fields/input-string/input-string.component';

@Component({
    selector: 'app-first-name-input',
    imports: [
        InputStringComponent
    ],
    templateUrl: './first-name-input.component.html',
    styleUrl: './first-name-input.component.scss'
})
export class FirstNameInputComponent {
  firstName = model<string>();
  valid = model<boolean>(false);
  disabled = input<boolean>(false);
  required = input<boolean>(false);
}
