import { Injectable } from '@angular/core';
import { ServerConfig } from '@models/server-config';
import { AuthConfig } from '@auth0/auth0-angular';
import { AuthConfigs } from '@app/config/auth-configs';
import { ServerUrls } from '@app/config/server-urls';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class ServerConfigService {
  public async getServerConfig(): Promise<ServerConfig> {
    if(!environment.local) {
      localStorage.removeItem('serverType');
      return environment.production ? "production" : "development";
    }

    const serverType = localStorage.getItem('serverType') as ServerConfig | null;
    return serverType ?? 'local';
  }

  public async getAuthConfig(): Promise<AuthConfig> {
    const serverType = await this.getServerConfig();
    return AuthConfigs[serverType];
  }

  public async getServerUrl(): Promise<string> {
    const serverType = await this.getServerConfig();
    return ServerUrls[serverType];
  }

  public async setServerConfig(serverType: ServerConfig): Promise<void> {
    const currentServerType = await this.getServerConfig();
    if(currentServerType === serverType) {
      return;
    }

    localStorage.setItem('serverType', serverType);
    window.location.reload();
  }
}
