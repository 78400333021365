import { inject, Injectable } from '@angular/core';
import {
  ExerciseEquipment,
  ExerciseJoint,
  FunctionalTest,
  FunctionalTestCreation,
  FunctionalTestFilters,
  FunctionalTestID
} from '@models';
import { ApiService } from '@services/API/api.service';

/**
 * A service for fetching and creating functional tests.
 * @class
 */
@Injectable({
  providedIn: "root"
})
export class FunctionalTestService {
  private readonly api = inject(ApiService);

  /**
   * Fetch an exercise by its ID.
   * @param exercise_id - The ID of the exercise to fetch.
   * @returns A promise that resolves to the exercise.
   * @throws {Error} If the request fails.
   */
  public getFunctionalTestByID(exercise_id: FunctionalTestID): Promise<FunctionalTest> {
    return this.api.get<FunctionalTest>(`functional_tests/${exercise_id}`);
  }

  public getEquipmentByFunctionalTestID(exercise_id: FunctionalTestID): Promise<ExerciseEquipment[]> {
    return this.api.get<ExerciseEquipment[]>(`functional_tests/${exercise_id}/equipment`);
  }

  public getJointsByFunctionalTestID(exercise_id: FunctionalTestID): Promise<ExerciseJoint[]> {
    return this.api.get<ExerciseJoint[]>(`functional_tests/${exercise_id}/joints`);
  }

  /**
   * Fetches exercises with optional query parameters.
   * @param filters - The optional query parameters.
   * @returns A promise that resolves to an object containing an array of exercises and the total number of exercises.
   */
  public getFunctionalTests(filters: FunctionalTestFilters): Promise<{
    data: FunctionalTest[],
    total: number
  }> {
    return this.api.get<{ data: FunctionalTest[], total: number }>("functional_tests", {
      "page": filters?.pageParams?.page.toString(),
      "limit": filters?.pageParams?.limit.toString(),
      "search": filters?.search,
      "camera_view": filters?.camera_view,
      "position": filters?.position,
      "rom_enabled": filters?.rom_enabled?.toString(),
      "reps_enabled": filters?.reps_enabled?.toString()
    });
  }

  /**
   * Creates a new functional test.
   * @param functionalTest - The functional test to create.
   * @returns A promise that resolves to a boolean indicating whether the functional test was created successfully.
   */
  public createFunctionalTest(functionalTest: FunctionalTestCreation): Promise<string> {
    return this.api.post<string>("functional_tests", functionalTest, { responseType: "text" });
  }


  public updateFunctionalTest(functionalTestID: FunctionalTestID, functionalTest: FunctionalTestCreation): Promise<string> {
    return this.api.put<string>(`functional_tests/${functionalTestID}`, functionalTest, { responseType: "text" });
  }

  public deleteFunctionalTest(functionalTestID: FunctionalTestID): Promise<string> {
    return this.api.delete<string>(`functional_tests/${functionalTestID}`, { responseType: "text" });
  }
}
