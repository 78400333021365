import { Component, input, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'app-input[type="text"]',
    imports: [
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    templateUrl: './input-string.component.html',
    styleUrl: './input-string.component.scss'
})
export class InputStringComponent {
  value = model<string>();
  valid = model<boolean>(false);
  placeholder = model<string>();
  label = model<string>();
  disabled = input<boolean>(false);
  required = input<boolean>(false);
  minLength = input<number>(0);
  maxLength = input<number>(1024);
}
