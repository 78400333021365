import { Component, input, model, signal } from '@angular/core';
import { SelectComponent } from '@components/forms/fields/select/select.component';
import { PatientSex } from '@models';

@Component({
    selector: 'app-patient-sex-select',
    imports: [
        SelectComponent
    ],
    templateUrl: './patient-sex-select.component.html',
    styleUrl: './patient-sex-select.component.scss'
})
export class PatientSexSelectComponent {
  sex = model<PatientSex>();
  valid = model<boolean>(false);
  disabled = input<boolean>(false);
  required = input<boolean>(false);
  public options = signal<{value: PatientSex, name: string}[]>([
    {value: "Male", name: "Male"},
    {value: "Female", name: "Female"},
    {value: "Other", name: "Other"},
  ])
}
